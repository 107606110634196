<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18.75 25">
    <path
      :style="iconStyle"
      d="M10.938-15.234a1.13,1.13,0,0,0,.342.83,1.13,1.13,0,0,0,.83.342H18.75V1.953a1.13,1.13,0,0,1-.342.83,1.13,1.13,0,0,1-.83.342H1.172a1.13,1.13,0,0,1-.83-.342A1.13,1.13,0,0,1,0,1.953V-20.7a1.13,1.13,0,0,1,.342-.83,1.13,1.13,0,0,1,.83-.342h9.766ZM14.063-3.711V-4.1a.518.518,0,0,0-.586-.586h-8.2a.518.518,0,0,0-.586.586v.391a.518.518,0,0,0,.586.586h8.2A.518.518,0,0,0,14.063-3.711Zm0-3.125v-.391a.518.518,0,0,0-.586-.586h-8.2a.518.518,0,0,0-.586.586v.391a.518.518,0,0,0,.586.586h8.2A.518.518,0,0,0,14.063-6.836Zm0-3.516a.518.518,0,0,0-.586-.586h-8.2a.518.518,0,0,0-.586.586v.391a.518.518,0,0,0,.586.586h8.2a.518.518,0,0,0,.586-.586Zm4.688-5.566v.293H12.5v-6.25h.293a1.13,1.13,0,0,1,.83.342l4.785,4.785A1.13,1.13,0,0,1,18.75-15.918Z"
      transform="translate(0 21.875)"
    />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '25',
    },
    width: {
      type: String,
      default: '18.75',
    },
    color: {
      type: String,
      default: 'icon-color-grey',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
